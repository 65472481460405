<template>
  <div class="c_task_details_bottom_upload">
    <van-uploader :disabled="disableComp" :before-read="beforeRead" :after-read="afterRead" v-show="!userAvator">
      <div class="c-task-details-bottom-upload-box g-flex-column g-flex-align-center g-flex-justify-center">
        <i class="iconfont icon-shangchuantupian"></i>
      </div>
    </van-uploader>

    <div class="c-task-details-bottom-upload-image" v-show="userAvator">
      <img :src="userAvator" @click="imagePreviewClick"/>
      <div class="c-task-details-bottom-upload-image-close" v-show="showComp" @click="deleteUploadImg">
        <i class="iconfont icon-guanbi1"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import { ImagePreview } from 'vant';
  import { apiUpLoadHeadImg } from '@/utils/api.js'
  export default {
    props: {
      canUpload: {
        type: Boolean,
        default() {
          return true
        } 
      },
      // 1 操作任务 2查看详情
      seeStatus: {
        type: Number,
        default() {
          return 1
        }
      },
      imgUrl: {
        type: String,
        default() {
          return ''
        }
      },
      // uploadText: {
      //   type: String,
      //   default() {
      //     return '上传图片'
      //   }
      // },
      // uploadType: {
      //   type: Number,
      //   default() {
      //     return 0
      //   }
      // }
    },
    data() {
      return {
        limitPng: ['image/jpeg','image/jpg','image/png','image/JPEG','image/JPG','image/PNG'],
        userAvator: '',
        imgShow: false,
      }
    },
    methods: {
      imagePreviewClick() {
        ImagePreview([this.userAvator]);
      },

      // 上传之前
      beforeRead(file) {
        console.log(file)
        if(this.limitPng.indexOf(file.type) === -1) {
          this.$toast('请上传jpeg、jpg、png格式的图片')
          return false
        }
        return true
      },

      // 上传
      async afterRead(file) {
        // formdata提交
        let formData = new FormData()
        formData.append('file', file.file)
        this.$global.loadingShow = true
        const { success, data } = await apiUpLoadHeadImg(formData)
        if(!success) return false
        this.userAvator = data.url
        this.$toast(data.msg)
        this.$emit('update:imgUrl', this.userAvator)
      },

      // 删除
      deleteUploadImg() {
        this.userAvator = ''
        this.$emit('update:imgUrl', this.userAvator)
      }
    },
    computed: {
      showComp() {
        if(this.seeStatus == 1) {
          if(this.canUpload) return true
          else return false
        }else {
          return false
        }
      },
      disableComp() {
        if(this.seeStatus == 1) {
          if(this.canUpload) return false
          else return true
        }else {
          return true
        }
      }
    },
    watch: {
      // 详情进来需要赋值
      imgUrl: {
        handler(nval) {
          if(!nval) return this.userAvator = ''
          this.userAvator = nval
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss">
.c_task_details_bottom_upload {
  flex: 1;
  .van-uploader {
    .c-task-details-bottom-upload-box {
      min-width: 80px;
      min-height: 80px;
      border-radius: 3px;
      background: $white;
      .iconfont {
        color: #D1D0D0;
        font-size: 40px;
      }
      .c-bind-upload-title {
        padding-top: 4px;
        font-size: 14px;
        color: #8F8F8F;
      }
    }
  }

  .c-task-details-bottom-upload-image {
    position: relative;
    display: inline-block;
    .c-task-details-bottom-upload-image-close {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(60%) translateY(-60%);
      .iconfont {
        font-size: 24px;
      }
    }
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 3px;
    }
  }
}
</style>