<template>
  <div class="c_task_main_liulan">
    <van-popup v-model="show" position="bottom" :style="{ height: 'auto' }" class="c-task-main-liulan-container">
      <div class="c-task-main-liulan-close" @click="onCancel">
       <i class="iconfont icon-guanbi"></i> 
      </div>
      <div class="c-task-main-liulan-top">
        <p class="c-task-main-liulan-title">主宝贝浏览</p>
      </div>
      <div class="c-task-main-liulan-bottom">
        <p class="c-task-main-liulan-bottom-tip">如果勾选项与店家要求不符将无法获取本任务佣金</p>
        <p class="c-task-main-liulan-bottom-title">选择是否已进行主宝贝浏览：</p>
        <van-checkbox-group v-model="checkList" checked-color="#FA436A">
          <van-cell-group>
            <van-cell :title="'我已' + item.title" clickable @click="checkboxItemClick(index)" v-for="(item, index) in mainLiuLanList" :key="item.id">
              <template #right-icon>
                <van-checkbox ref="checkboxes" :name="item.id" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>


      <div class="c-task-main-liulan-btn">
        <ul class="c-task-main-liulan-btn-list g-flex-align-center">
          <li class="c-task-main-liulan-btn-item g-flex-justify-center g-flex-align-center" @click="onCancel">
            <span>关闭</span>
          </li>
          <li class="c-task-main-liulan-btn-item g-flex-justify-center g-flex-align-center" @click="onConfirm">
            <span>确认</span>
          </li>
        </ul>

      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        checkList: [],
        show: false,
        mainLiuLanList: [],
      }
    },
    methods: {
      onCancel() {
        this.resetData()
      },
      onConfirm() {
        if(this.checkList.length != this.mainLiuLanList.length) return this.$toast('请按照商家要求勾选您已操作的主宝贝浏览')
        this.$emit('emitConfirmManLiuLan', this.checkList)
        this.resetData()
      },
      
      resetData() {
        this.show = false
        this.checkList = []
        this.mainLiuLanList = []
      },

      checkboxItemClick(index) {
        this.$refs.checkboxes[index].toggle()
      },

      onShow(flag, mainLiuLanList = []) {
        this.mainLiuLanList = mainLiuLanList
        console.log('mainLiuLanList', this.mainLiuLanList)
        this.show = flag
      },
    },
  }
</script>

<style lang="scss">
.c_task_main_liulan {
  .c-task-main-liulan-container {
    padding-top: 20px;

    .c-task-main-liulan-close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      .iconfont {
        font-size: 20px;
        color: #828282;
      }
    }

    .c-task-main-liulan-top {
      font-size: 16px;
      color: #000000;
      text-align: center;
    }
    
    .c-task-main-liulan-bottom {
      padding-bottom: 30px;
      .van-cell__title {
        color: #323233;
      }
      .c-task-main-liulan-bottom-tip {
        margin: 16px;
        padding: 8px 12px;
        background: #EEEEEE;
        color: #323233;
        font-size: 13px;
      }
      .c-task-main-liulan-bottom-title {
        font-size: 16px;
        color: #878585;
        padding: 10px 16px;
      }
    }

    .c-task-main-liulan-btn {
      padding: 15px 16px 30px 16px;
      .c-task-main-liulan-btn-list {
        background: #FA436A;
        border-radius: 20px;
        .c-task-main-liulan-btn-item {
          flex: 1;
          color: $white;
          font-size: 16px;
          padding: 10px 0;
          span {
            flex: 1;
            text-align: center;
          }
          &:nth-of-type(1) {
            span {
              border-right: 1px solid $white;
            }
          }
          &:nth-last-of-type(1) {
            span {
              border-left: 1px solid $white;
            }
          }
        }
      }
    }

  }
}
</style>